import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header"href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/contact-us/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/contact-us/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/contactos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/contactos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/contact-us/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/contact-us/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>CONTACTO</h2>
                <p>
                  Si deseas ponerte en contacto con nosotros, tienes alguna duda
                  o comentario sobre nuestra <br />{" "}
                  <a
                    title="Política de privacidad"
                    href="/es/legal/politicia-de-privacidad/"
                  >
                    Política de privacidad
                  </a>{" "}
                  o nuestra página web puedes escribirnos a{" "}
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                    target="_blank"
                  >
                    este correo electrónico
                  </a>
                  .
                </p>
                <p> </p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
